import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Typography is defined as the science of style, appearance, and structure of fonts that aim to provide convenience and aesthetics to the reader. Effective typography depends not only on the content but also on the presentation visual of the typography itself, starting from the font type, font size and line height. Check out the guideline for more detail description.`}</p>
    <div className="divi" />
    <h2>{`Texts`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Paragraph, SizableText, Text } from '@legion-crossplatform/ui';

export default () => (
  <>
    <Text>Text</Text>
    <SizableText>Sizable Text</SizableText>
    <Paragraph>Paragraph</Paragraph>
  </>
)
`}</code></pre>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Description, Paragraph, Button, Text Field, Tabs, etc`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Text } from '@legion-crossplatform/ui';

export function Text = () => {
    return (
        <Text>Text</Text>
    )
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Headings`}</h2>
    <p><strong parentName="p">{`Common usage :`}</strong>{` Hero section, Title content or component, Subtitle of title`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preview`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Line Height`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='reactNativeHeading1'>{`Heading 1`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`53dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='reactNativeHeading2'>{`Heading 2`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='reactNativeHeading3'>{`Heading 3`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='reactNativeHeading4'>{`Heading 4`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='reactNativeHeading5'>{`Heading 5`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><p className='reactNativeHeading6'>{`Heading 6`}</p></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`heading6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13sp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24dp`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { H1, H2, H3, H4, H5, H6, YStack } from '@legion-crossplatform/ui'

export function HeadingsDemo() {
  return (
    <YStack alignSelf="center">
      <H1>Heading 1</H1>
      <H2>Heading 2</H2>
      <H3>Heading 3</H3>
      <H4>Heading 4</H4>
      <H5>Heading 5</H5>
      <H6>Heading 6</H6>
    </YStack>
  )
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      